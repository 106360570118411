import GoldCardImageHover from 'share-svgs/svgs/gold-card-hover.svg'
import GoldCardImage from 'share-svgs/svgs/gold-card.svg'
import HighlightCardImageHover from 'share-svgs/svgs/highlight-card-hover.svg'
import HighlightCardImage from 'share-svgs/svgs/highlight-card.svg'
import PowerCardImageHover from 'share-svgs/svgs/power-card-hover.svg'
import PowerCardImage from 'share-svgs/svgs/power-card.svg'
import { Box, Flex, Text } from 'ui'
import { styled } from 'ui/theme/stitches.config'

export const HighlightCard = () => {
    return (
        <Box
            css={{
                backgroundColor: '$teal_500',
                borderRadius: 16,
                padding: '40rem 50rem',
                '@md': {
                    padding: '50rem 60rem'
                },
                '@lg': {
                    padding: '60rem 70rem'
                },

                '& .hoverImage': {
                    display: 'none'
                },
                '&:hover .defaultImage': {
                    display: 'none'
                },
                '&:hover .hoverImage': {
                    display: 'block'
                },
                '&:hover': {
                    backgroundColor: '$teal_400'
                }
            }}
            gap="4"
        >
            <Text weight={'bold'} size="5" css={{ lineHeight: '130%' }}>
                Find the moments that resonate.
            </Text>
            <Text>
                Highlight your favorite insights—discover what stands out and
                sparks meaningful conversation.
            </Text>
            <HighlightCardImage className="defaultImage" />
            <HighlightCardImageHover className="hoverImage" />
        </Box>
    )
}

export const GoldCard = () => {
    return (
        <Box
            css={{
                backgroundColor: '$orange_500',
                borderRadius: 16,
                padding: '40rem 50rem',
                '@md': {
                    padding: '50rem 60rem'
                },
                '@lg': {
                    padding: '60rem 70rem'
                },

                '& .hoverImage': {
                    display: 'none'
                },
                '&:hover .defaultImage': {
                    display: 'none'
                },
                '&:hover .hoverImage': {
                    display: 'block'
                },
                '&:hover': {
                    backgroundColor: '$orange_400'
                }
            }}
            gap="4"
        >
            <Text weight={'bold'} size="5" css={{ lineHeight: '130%' }}>
                Get to the gold!
            </Text>
            <Text
                css={{
                    '@md': {
                        mb: '20px'
                    },
                    '@lg': {
                        mb: '30px'
                    }
                }}
            >
                Not everything makes the cut - supercharge your quality curation
                with speed and simplicity.
            </Text>
            <GoldCardImage className="defaultImage" />
            <GoldCardImageHover className="hoverImage" />
        </Box>
    )
}

export const PowerCard = () => {
    return (
        <Box
            css={{
                backgroundColor: '$magenta_500',
                borderRadius: 16,
                padding: '40rem 50rem',
                '@md': {
                    padding: '50rem 60rem'
                },
                '@lg': {
                    padding: '60rem 70rem'
                },

                '& .hoverImage': {
                    display: 'none'
                },
                '&:hover .defaultImage': {
                    display: 'none'
                },
                '&:hover .hoverImage': {
                    display: 'block'
                },
                '&:hover': {
                    backgroundColor: '$magenta_400'
                }
            }}
        >
            <Text
                weight={'bold'}
                size="5"
                css={{
                    lineHeight: '130%',
                    maxWidth: '220px'
                }}
            >
                Your voice shapes the story.
            </Text>
            <Text
                css={{
                    position: 'relative',
                    bottom: '-20rem'
                }}
            >
                Highlight, remix, and elevate—help shape the narrative and
                amplify what truly matters.
            </Text>
            <Flex justifyContent={'flexEnd'}>
                <StyledPowerCardImage>
                    <PowerCardImage className="defaultImage" />
                </StyledPowerCardImage>
                <StyledPowerCardImage>
                    <PowerCardImageHover className="hoverImage" />
                </StyledPowerCardImage>
            </Flex>
        </Box>
    )
}

const StyledPowerCardImage = styled('div', {
    height: '160px',
    '& svg': {
        position: 'relative',
        bottom: '-25rem',
        '@md': { bottom: '-35rem' },
        '@lg': { bottom: '-45rem' }
    }
})
