import DesktopWrap from './DesktopWrap'
import MobileWrap from './MobileWrap'
import TabletWrap from './TabletWrap'

export default function CardsSection() {
    return (
        <div>
            <MobileWrap />
            <TabletWrap />
            <DesktopWrap />
        </div>
    )
}
