import LandingPage from '@components/LandingPage'
import { NextSeo } from 'next-seo'
import { Fragment } from 'react'

export default function LandingNextPage() {
    return (
        <Fragment>
            <NextSeo title="Statemints" />
            <LandingPage />
        </Fragment>
    )
}
