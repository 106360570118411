import { motion } from 'motion/react'
import { useRouter } from 'next/router'
import { Box, Flex, Text, VStack } from 'ui'
import { styled } from 'ui/theme/stitches.config'

const PartnersSection = () => {
    return (
        <Box css={{ zIndex: 1, p: '$4', mt: '$8', '@lg': { p: '50px 80px' } }}>
            <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0,
                    ease: [0.25, 0.1, 0.25, 1]
                }}
                viewport={{ once: true, amount: 0.1 }}
            >
                <VStack
                    spacing={'7'}
                    alignItems={'flexStart'}
                    css={{
                        mb: '$10'
                    }}
                >
                    <Text
                        as="h2"
                        size={'5'}
                        weight={'ultraBold'}
                        css={{ '@md': { fontSize: '40px', mb: '$3' } }}
                    >
                        Trusted By and Empowering...
                    </Text>
                    <Flex
                        css={{
                            flexDirection: 'column',
                            '@lg': { flexDirection: 'row' }
                        }}
                        gap={'9'}
                    >
                        {Partners.map((partner, index) => (
                            <PartnersCard key={index} {...partner} />
                        ))}
                    </Flex>
                </VStack>
            </motion.div>
        </Box>
    )
}

const PartnersCard = ({
    title,
    description,
    imgSrc,
    route
}: {
    title: string
    description: string
    imgSrc: string
    route?: string
}) => {
    const router = useRouter()

    const handleClick = () => {
        if (route) {
            router.push(route)
        }
    }

    return (
        <Box
            bg={'dark_navy'}
            bRadius={'3'}
            css={{
                border: '1px solid gray',
                overflow: 'hidden',
                cursor: route ? 'pointer' : 'default'
            }}
            onClick={route ? handleClick : undefined}
        >
            <VStack alignItems={'flexStart'} spacing={'1'}>
                <ImageContainer>
                    <img src={imgSrc} alt={title} />
                </ImageContainer>
                <VStack
                    alignItems={'flexStart'}
                    spacing={'2'}
                    css={{ p: '$4', minHeight: '160rem' }}
                >
                    <Text weight={'bold'} size={'4'}>
                        {title}
                    </Text>
                    <Text weight={'medium'}>{description}</Text>
                </VStack>
            </VStack>
        </Box>
    )
}

const ImageContainer = styled('div', {
    width: '100%',
    height: '190px', // Use px instead of rem
    position: 'relative',

    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center bottom',
        display: 'block'
    },

    '@lg': {
        height: '240px'
    },
    '@hq': {
        height: '300px'
    }
})

const Partners = [
    {
        title: 'Healthcare',
        description: `Children's Nebraska utilizes 'Statemints' to gain clarity on how to best support their staff—from nurses and doctors to essential workers—streamlining operations and uncovering solutions to workplace challenges.`,
        imgSrc: '/new-landing/childrens-nebraska.webp'
    },
    {
        title: 'Conferences',
        description: `The Austin Film Festival leverages 'Statemints' to empower their attendees to capture and save the moments and insights that matter most.`,
        imgSrc: '/new-landing/austin-film-festival.webp',
        route: '/aff'
    }
]

export default PartnersSection
