import { motion, useInView } from 'motion/react'
import { useRef, useState } from 'react'
import { styled } from 'ui/theme/stitches.config'
import { GoldCard, HighlightCard, PowerCard } from './Cards'

const StyledTabletWrap = styled('div', {
    display: 'none',
    width: '58%',
    height: '950px',
    '@md': { display: 'block' },
    '@lg': { display: 'none' }
})

const TabletWrap = () => {
    const CardsInViewRef = useRef(null)

    const isCardsInView = useInView(CardsInViewRef, {
        once: true,
        amount: 0.1
    })

    const [highlightZIndex, setHighlightZIndex] = useState(3)
    const [goldZIndex, setGoldZIndex] = useState(2)
    const [powerZIndex, setPowerZIndex] = useState(1)

    const bringToFront = (cardType: string) => {
        const highestZ = Math.max(highlightZIndex, goldZIndex, powerZIndex)
        const newZ = highestZ + 1

        if (cardType === 'highlight') {
            setHighlightZIndex(newZ)
        } else if (cardType === 'gold') {
            setGoldZIndex(newZ)
        } else if (cardType === 'power') {
            setPowerZIndex(newZ)
        }
    }

    return (
        <StyledTabletWrap ref={CardsInViewRef}>
            <motion.div
                onClick={() => bringToFront('highlight')}
                initial={{ opacity: 0, y: 100 }}
                animate={isCardsInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: 0 }}
                style={{
                    position: 'relative',
                    top: '50px',
                    left: '50px',
                    width: '85%',
                    zIndex: highlightZIndex,
                    willChange: 'transform, opacity'
                }}
            >
                <div style={{ transform: 'rotate(-8deg)' }}>
                    <HighlightCard />
                </div>
            </motion.div>

            <motion.div
                onClick={() => bringToFront('gold')}
                initial={{ opacity: 0, y: 100 }}
                animate={isCardsInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.5 }}
                style={{
                    position: 'relative',
                    top: '-150px',
                    right: '-245px',
                    width: '85%',
                    zIndex: goldZIndex,
                    willChange: 'transform, opacity'
                }}
            >
                <div style={{ transform: 'rotate(10deg)' }}>
                    <GoldCard />
                </div>
            </motion.div>

            <motion.div
                onClick={() => bringToFront('power')}
                initial={{ opacity: 0, y: 100 }}
                animate={isCardsInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: 1 }}
                style={{
                    position: 'relative',
                    top: '-250px',
                    right: '-340px',
                    width: '85%',
                    zIndex: powerZIndex,
                    willChange: 'transform, opacity'
                }}
            >
                <div style={{ transform: 'rotate(10deg)' }}>
                    <PowerCard />
                </div>
            </motion.div>
        </StyledTabletWrap>
    )
}

export default TabletWrap
