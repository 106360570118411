import MuxPlayer from '@mux/mux-player-react'
import { useRouter } from 'next/router'
import { Box, Button, HighlightedText, Text, VStack } from 'ui'
import { styled } from 'ui/theme/stitches.config'

const LandingHero = () => {
    const router = useRouter()

    const handleRedirectToRoute = (route: string) => {
        router.push(route)
    }

    return (
        <Box
            css={{
                zIndex: 1,
                padding: '20px',
                '@lg': {
                    padding: '50px 80px'
                }
            }}
        >
            <VStack alignItems={'flexStart'}>
                <Text
                    as="h1"
                    css={{
                        fontSize: '42px',
                        fontWeight: '$ultraBold',
                        lineHeight: '48px',
                        letterSpacing: '-2px',
                        textTransform: 'uppercase',
                        '@md': {
                            fontSize: '62px',
                            lineHeight: '70px',
                            letterSpacing: '-3px'
                        },
                        '@lg': {
                            fontSize: '82px',
                            lineHeight: '90px'
                        }
                    }}
                >
                    Exclusive access. <br /> Insightful conversations. <br />{' '}
                    <HighlightedText>Highlight What Matters.</HighlightedText>
                </Text>
                <Text
                    css={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        lineHeight: '28px',
                        letterSpacing: '0.22px',
                        color: 'linear-gradient(219deg, #CE261B 15.55%, #9B6CFF 51.73%, #FFC397 83.43%))',
                        '@md': {
                            fontSize: '48px',
                            lineHeight: '56px'
                        }
                    }}
                >
                    <GradientText>Make a Statemint.</GradientText>
                </Text>
                <VStack spacing={'8'}>
                    <Box
                        bRadius={'3'}
                        alignItems={'center'}
                        css={{
                            position: 'relative',
                            overflow: 'hidden',
                            height: '230px',
                            width: '345px',
                            mt: '20px',
                            '@md': {
                                height: '450px',
                                width: '650px',
                                mt: '40px'
                            },
                            '@lg': {
                                height: '500px',
                                width: '900px'
                            },
                            'mux-player': {
                                // maxWidth: '900px',
                                borderRadius: '3px',
                                '--controls': 'none',
                                '--media-object-fit': 'cover',
                                '--border-radius': '3px', // Mux Player custom property for border radius
                                '--media-border-radius': '3px' // Additional property for the media element
                            }
                        }}
                    >
                        <MuxPlayer
                            streamType="on-demand"
                            playbackId="gQJjFlflQT5Md02B2drsvs00c0202O02g3rea7dj02tpzyK9A"
                            metadata={{
                                video_id: 'hero',
                                video_title: 'statemints-hero-video'
                            }}
                            autoPlay={'muted'}
                            loop
                            muted
                            style={{
                                position: 'absolute',
                                borderRadius: '3px',
                                inset: 0,
                                zIndex: 0
                            }}
                        />
                    </Box>

                    <Button
                        color="primary"
                        css={{
                            color: '$colors$ivory',
                            '@lg': {
                                mb: '40px'
                            }
                        }}
                        onClick={() => handleRedirectToRoute('/get-started')}
                    >
                        Get Access
                    </Button>
                </VStack>
            </VStack>
        </Box>
    )
}

const GradientText = styled('span', {
    background: 'linear-gradient(90deg, #FFA77E, #C080F7)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 'bold'
})

export default LandingHero
