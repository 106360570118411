import { Box, Flex } from 'ui'
import { styled } from 'ui/theme/stitches.config'
import CardsSection from './CardsSection'
import HowAreStatemintsSection from './HowAreStatemintsSection'
import LandingHero from './LandingHero'
import PartnersSection from './PartnersSection'

const LandingPage = () => {
    return (
        <Box
            css={{
                position: 'relative'
            }}
        >
            <Flex
                direction={'col'}
                css={{
                    position: 'absolute',
                    zIndex: 0,
                    height: '100%'
                }}
            >
                <GradientBackground />
                <GradientBackground
                    css={{
                        marginTop: '-500px',
                        '@md': { display: 'none', marginTop: '0px' }
                    }}
                />{' '}
            </Flex>
            <div style={{ zIndex: 1 }}>
                <LandingHero />
                <CardsSection />
                <HowAreStatemintsSection />
                <PartnersSection />
            </div>
        </Box>
    )
}

const BackgroundContainer = styled('div', {
    backgroundColor: 'transparent',
    position: 'relative',
    width: '100%',
    height: '100%'
})

const GradientBackground = styled(BackgroundContainer, {
    '&::before': {
        content: '""',
        position: 'absolute',
        width: '300px',
        height: '2300px',
        top: '-500px',
        left: '40%',
        background:
            'linear-gradient(355deg, rgba(16, 4, 37, 0.50) 55.03%, rgba(16, 4, 37, 0.10) 95.79%)',
        filter: 'blur(52.5px)',
        transform: 'rotate(-40deg)',
        transformOrigin: 'center center',
        zIndex: 0,
        '@md': {
            background:
                'linear-gradient(355deg, rgba(16, 4, 37, 0.80) 55.03%, rgba(16, 4, 37, 0.17) 95.79%)',
            width: '1000px',
            height: '2500px',
            top: '-100px',
            left: '10%'
        },
        '@lg': {
            top: '-200px',
            left: '0%',
            width: '900px',
            height: '1000px'
        }
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        width: '300px',
        height: '2300px',
        top: '0px',
        left: '30%',
        background:
            'linear-gradient(355deg, rgba(16, 4, 37, 0.50) 55.03%, rgba(16, 4, 37, 0.10) 95.79%)',
        filter: 'blur(52.5px)',
        transform: 'rotate(40deg)',
        transformOrigin: 'center center',
        zIndex: 0,
        '@md': {
            background:
                'linear-gradient(355deg, rgba(16, 4, 37, 0.80) 55.03%, rgba(16, 4, 37, 0.17) 95.79%)',
            width: '1000px',
            height: '2500px',
            top: '600px',
            left: '70%'
        },
        '@lg': {
            top: '500px',
            height: '2500px',
            width: '1000px',
            left: '10%'
        }
    },

    '& > *': {
        position: 'relative',
        zIndex: 1
    }
})

export default LandingPage
