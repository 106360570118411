import { motion } from 'motion/react'
import React from 'react'
import FeltIcon from 'share-svgs/svgs/felt-icon.svg'
import MadeIcon from 'share-svgs/svgs/made-icon.svg'
import RememberedIcon from 'share-svgs/svgs/remembered-icon.svg'
import { Box, Flex, HStack, Text, VStack } from 'ui'

const HowAreStatemintsSection = () => {
    return (
        <Box css={{ zIndex: 1, p: '$4', '@lg': { p: '50px 80px' } }}>
            <motion.div
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0,
                    ease: [0.25, 0.1, 0.25, 1]
                }}
                viewport={{ once: true, amount: 0.1 }}
            >
                <VStack
                    justifyContent={'flexStart'}
                    alignItems={'flexStart'}
                    spacing={'8'}
                >
                    <Text
                        as="h2"
                        size={'5'}
                        weight={'ultraBold'}
                        css={{ '@md': { fontSize: '40px' } }}
                    >
                        How are Statemints...{' '}
                    </Text>
                    <Flex
                        css={{
                            flexDirection: 'column',
                            '@lg': { flexDirection: 'row' }
                        }}
                        gap={'7'}
                    >
                        {HowAreStatemintsCards.map((card, index) => (
                            <HowAreStatemintsCard key={index} {...card} />
                        ))}
                    </Flex>
                </VStack>
            </motion.div>
        </Box>
    )
}

const HowAreStatemintsCard = ({
    title,
    description,
    Icon
}: {
    title: string
    description: string
    Icon: React.ComponentType
}) => {
    return (
        <HStack alignItems={'flexStart'}>
            <div style={{ width: '48rem', height: '48rem' }}>
                <Icon />
            </div>

            <VStack alignItems={'flexStart'} spacing={'2'}>
                <Text weight={'bold'} size={'4'}>
                    {title}
                </Text>
                <Text weight={'medium'}>{description}</Text>
            </VStack>
        </HStack>
    )
}

const HowAreStatemintsCards = [
    {
        title: 'Made',
        description:
            'You highlight what resonates most. Our platform transforms those moments into powerful Statemints, combining your selections and amplifying what matters to you.',
        Icon: MadeIcon
    },
    {
        title: 'Felt',
        description: `Your insights go beyond the surface, sparking new ideas and deeper connections. You're not just engaging—you're inspiring, shaping unique takeaways that make an impact.`,
        Icon: FeltIcon
    },
    {
        title: 'Remembered',
        description:
            'The Statemints you save create a ripple effect, keeping the conversation going. Your highlights drive discussions, spark collaboration, and leave a lasting impression.',
        Icon: RememberedIcon
    }
]

export default HowAreStatemintsSection
